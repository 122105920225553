import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FineRideTable from '../../Components/FineRideTable';
import { getLastRideForFine } from '../../state/fine';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../../Components/Loader';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment-timezone';

import momentLocalizer from 'react-widgets-moment';

import './index.scss';

moment.locale('en');
momentLocalizer();

const Fine = (props) => {
  const [feedback, setFeedback] = useState('');
  const [vehicle_name, setVehicle_name] = useState('');
  const [ride_date, setRide_date] = useState(new Date());
  const [loaded, setLoaded] = useState(true);
  const [fine_ride_data, setFineRideData] = useState([]);

  const onSubmitVehicle = (submitVal) => {
    setLoaded(false);
    setFeedback('');
    submitVal.preventDefault();
    const params = {
      vehicle_name: vehicle_name,
      timestamp: moment(ride_date).tz('America/Los_Angeles').format('MMM DD YYYY HH:mm:ss'),
      infraction_type: 'parking',
      timezone: 'PST',
    };
    props.actions
      .getLastRideForFine(params)
      .then(() => {
        let fine_ride_data_array = [];
        fine_ride_data_array.push(props.fine);
        setLoaded(true);
        setFineRideData(fine_ride_data_array);
      })
      .catch((error) => {
        setLoaded(true);
        setFeedback(error);
      });
  };

  const onClickReset = (event) => {
    event.preventDefault();
    setVehicle_name('');
    setFineRideData([]);
  };

  return (
    <AuthenticatedPage className="edit_vehicle_container">
      <div className="page-title">Fine Lookup</div>
      <form onSubmit={onSubmitVehicle}>
        <div className="vehicle-input-section">
          <label className="form_input_section__label" htmlFor="vehicle_name">
            Vehicle QR Code
          </label>
          <input
            type="text"
            name="vehicle_name"
            placeholder="Vehicle QR Code"
            value={vehicle_name}
            onChange={(event) => setVehicle_name(event.target.value)}
            className="vehicle-name-input"
          />
        </div>
        <label className="form_input_section__label" htmlFor="vehicle_name">
          Day/Time of Ride End
        </label>
        <div className="dateSelectorContainer">
          <DateTimePicker
            editFormat={'YYYY-MM-DD'}
            format={'YYYY-MM-DD'}
            className="add_pricing_plan_form__data_input"
            defaultValue={new Date()}
            onChange={(value) => setRide_date(value)}
          />
        </div>
        <div className="form_input_section">
          <input type="submit" disabled={!loaded} />
          <button onClick={onClickReset}>Reset</button>
        </div>
      </form>
      {feedback && <p style={{ color: 'red' }}>{feedback.toString()}</p>}
      {fine_ride_data.length > 0 && <FineRideTable ride={fine_ride_data} />}
      <Loader loaded={loaded} />
    </AuthenticatedPage>
  );
};

Fine.propTypes = {
  actions: PropTypes.object,
  fine: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { fine } = state;
  return {
    fine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getLastRideForFine,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fine);
