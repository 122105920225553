import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

class FineRideTable extends Component {
  render() {
    const { ride } = this.props;
    return (
      <ReactTable
        className="-striped -highlight users_table user_table_add_on "
        data={ride}
        showPaginationBottom={false}
        pageSize={ride.length}
        width="100%"
        columns={[
          {
            Header: 'Ride Id',
            accessor: 'id',
            maxWidth: 350,
          },
          {
            Header: 'User Id',
            accessor: 'user_id',
            maxWidth: 250,
          },
          {
            Header: 'First Name',
            accessor: 'first_name',
            maxWidth: 100,
          },
          {
            Header: 'Last Name',
            accessor: 'last_name',
            maxWidth: 100,
          },
          {
            Header: 'Phone',
            accessor: 'phone',
            maxWidth: 100,
          },
          {
            Header: 'Start Location',
            accessor: 'start_location',
            maxWidth: 150,
          },
          {
            Header: 'End Location',
            accessor: 'end_location',
            maxWidth: 150,
          },
          {
            Header: 'Start Time',
            accessor: 'start_time',
            maxWidth: 200,
          },
          {
            Header: 'End Time',
            accessor: 'end_time',
            maxWidth: 200,
          },
        ]}
      />
    );
  }
}

FineRideTable.propTypes = {
  ride: PropTypes.object,
};

export default FineRideTable;
